import React, { useState } from "react";
import { Form, Button, Table } from "react-bootstrap";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const ReadSupportTicket = ({ ticket, onClose }) => {
  const [showResponseForm, setShowResponseForm] = useState(false);
  const [isEditingResponse, setIsEditingResponse] = useState(false);
  const [responseIndex, setResponseIndex] = useState(null);
  const [response, setResponse] = useState({
    user: "",
    message: "",
  });

  const handleResponseChange = (e) => {
    const { name, value } = e.target;
    setResponse((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleResponseSubmit = async (e) => {
    e.preventDefault();
    const newResponse = {
      ...response,
      date: new Date().toISOString(),
    };

    const updatedResponses = [...ticket.responses];
    if (isEditingResponse && responseIndex !== null) {
      updatedResponses[responseIndex] = newResponse;
    } else {
      updatedResponses.push(newResponse);
    }

    try {
      const ticketRef = doc(db, "SupportTickets", ticket.id);
      await updateDoc(ticketRef, {
        lastUpdateTime: new Date().toISOString(),
        responses: updatedResponses,
      });
      ticket.responses = updatedResponses;
      setShowResponseForm(false);
      setIsEditingResponse(false);
      setResponseIndex(null);
      setResponse({
        user: "",
        message: "",
      });
    } catch (error) {
      console.error("Error adding/editing response: ", error);
    }
  };

  const handleEditResponseClick = (response, index) => {
    setResponse(response);
    setResponseIndex(index);
    setIsEditingResponse(true);
    setShowResponseForm(true);
  };

  const handleAddResponseClick = () => {
    setResponse({
      user: "",
      message: "",
    });
    setIsEditingResponse(false);
    setShowResponseForm(true);
  };

  return (
    <div>
      <Form>
        <Form.Group controlId="formID" className="form-group">
          <Form.Label>ID</Form.Label>
          <Form.Control type="text" readOnly value={ticket.id} />
        </Form.Group>
        <Form.Group controlId="formuserId" className="form-group">
          <Form.Label>User ID</Form.Label>
          <Form.Control type="text" readOnly value={ticket.userId} />
        </Form.Group>
        <Form.Group controlId="formSubject" className="form-group">
          <Form.Label>Subject</Form.Label>
          <Form.Control type="text" readOnly value={ticket.subject} />
        </Form.Group>
        <Form.Group controlId="formMessage" className="form-group">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            readOnly
            value={ticket.message}
          />
        </Form.Group>
        <Form.Group controlId="formDeviceType" className="form-group">
          <Form.Label>Device Type</Form.Label>
          <Form.Control type="text" readOnly value={ticket.deviceType} />
        </Form.Group>
        <Form.Group controlId="formVersionNumber" className="form-group">
          <Form.Label>Version Number</Form.Label>
          <Form.Control type="text" readOnly value={ticket.versionNumber} />
        </Form.Group>
        <Form.Group controlId="formDate" className="form-group">
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="text"
            readOnly
            value={new Date(ticket.date).toLocaleString()}
          />
        </Form.Group>

        <h3>Responses</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>User</th>
              <th>Message</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {ticket.responses && ticket.responses.length > 0 ? (
              ticket.responses.map((response, index) => (
                <tr key={index}>
                  <td>{response.user}</td>
                  <td>{response.message}</td>
                  <td>{new Date(response.date).toLocaleString()}</td>
                  <td>
                    <Button
                      variant="info"
                      onClick={() => handleEditResponseClick(response, index)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No responses yet.</td>
              </tr>
            )}
          </tbody>
        </Table>

        {!showResponseForm && (
          <>
            <Button variant="primary" onClick={handleAddResponseClick}>
              Add Response
            </Button>
            <Button
              variant="secondary"
              onClick={onClose}
              style={{ marginLeft: "10px" }}
            >
              Close
            </Button>
          </>
        )}
      </Form>

      {showResponseForm && (
        <div style={{ marginTop: "20px" }}>
          <h3>{isEditingResponse ? "Edit Response" : "Add Response"}</h3>
          <Form onSubmit={handleResponseSubmit}>
            <Form.Group controlId="responseUser" className="form-group">
              <Form.Label>User</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="user"
                value={response.user}
                onChange={handleResponseChange}
              />
            </Form.Group>
            <Form.Group controlId="responseMessage" className="form-group">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter your message"
                name="message"
                value={response.message}
                onChange={handleResponseChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {isEditingResponse ? "Update Response" : "Submit Response"}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShowResponseForm(false);
                setIsEditingResponse(false);
                setResponseIndex(null);
                setResponse({
                  user: "",
                  message: "",
                });
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ReadSupportTicket;
