import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDCSAc4ocKpK7xiDOKeMCOGERIL0opVcEM",
  authDomain: "espiritu1.firebaseapp.com",
  databaseURL:
    "https://espiritu1-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "espiritu1",
  storageBucket: "espiritu1.appspot.com",
  messagingSenderId: "771827856417",
  appId: "1:771827856417:web:9a6c422edb69bbeeef1f60",
  measurementId: "G-4Y9XQ3KKN4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const database = getDatabase(app);

export { auth, db, database };
