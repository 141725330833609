import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "../App.css";

const EditTopic = ({ topic, subjects, onSave, onCancel }) => {
  const [formData, setFormData] = useState({ ...topic });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedTopic = {
      ...formData,
      lastUpdateTime: new Date().toISOString(),
    };
    onSave(updatedTopic);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formTitle" className="form-group">
        <Form.Control
          type="text"
          placeholder="Enter title"
          name="title"
          value={formData.title}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formsubjectId" className="form-group">
        <Form.Control
          as="select"
          name="subjectId"
          value={formData.subjectId}
          onChange={handleChange}
        >
          <option value="">Select Subject - None</option>
          {subjects.map((subject) => (
            <option key={subject.id} value={subject.id}>
              {subject.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="formTrainingVideoLocation" className="form-group">
        <Form.Control
          type="text"
          placeholder="Enter training video URL"
          name="trainingVideoLocation"
          value={formData.trainingVideoLocation}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formTrainingTextBody" className="form-group">
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter training text"
          name="trainingTextBody"
          value={formData.trainingTextBody}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="chatGPTSystemInput" className="form-group">
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter chatGPT system prompt text"
          name="chatGPTSystemInput"
          value={formData.chatGPTSystemInput}
          onChange={handleChange}
        />
      </Form.Group>
      <Button variant="success" type="submit" style={{ marginRight: '10px' }}>
        Save
      </Button>
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </Form>
  );
};

export default EditTopic;
