import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

const EditSupportTicket = ({ ticket, onSave, onCancel }) => {
  const [formData, setFormData] = useState({ ...ticket });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedSupportTicket = {
      ...formData,
      lastUpdateTime: new Date().toISOString(),
    };
    onSave(updatedSupportTicket);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formuserId" className="form-group">
        <Form.Label>User ID</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter User ID"
          name="userId"
          value={formData.userId}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formSubject" className="form-group">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formMessage" className="form-group">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter Message"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formDeviceType" className="form-group">
        <Form.Label>Device Type</Form.Label>
        <Form.Control
          type="text"
          placeholder="Device Type"
          name="deviceType"
          value={formData.deviceType}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formVersionNumber" className="form-group">
        <Form.Label>Version Number</Form.Label>
        <Form.Control
          type="text"
          placeholder="Version Number"
          name="versionNumber"
          value={formData.versionNumber}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formDate" className="form-group">
        <Form.Label>Date</Form.Label>
        <Form.Control
          type="text"
          readOnly
          value={new Date(formData.date).toLocaleString()}
        />
      </Form.Group>
      <Button variant="success" type="submit" style={{ marginRight: "10px" }}>
        Save
      </Button>
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </Form>
  );
};

export default EditSupportTicket;
