import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { auth } from "./firebase"; 

function MenuBar() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Navbar
      bg="light"
      expand="lg"
      style={{ paddingLeft: "20px", paddingRight: "20px" }}
    >
      <Navbar.Brand href="#home">Espiritu</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <LinkContainer to="/">
            <Nav.Link>Home</Nav.Link>
          </LinkContainer>
          {user && (
            <>
              <LinkContainer to="/subjects">
                <Nav.Link>Subjects</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/topics">
                <Nav.Link>Topics</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/supportTickets">
                <Nav.Link>SupportTickets</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/rtdatabase">
                <Nav.Link>RT-Database</Nav.Link>
              </LinkContainer>
            </>
          )}
        </Nav>
        <Nav className="ms-auto">
          {user ? (
            <>
              {/* Show OpenAI API Usage link only if user is logged in */}
              <Nav.Link
                href="https://platform.openai.com/usage"
                target="_blank"
                rel="noopener noreferrer"
              >
                OpenAI API Usage
              </Nav.Link>
              <Nav.Link href="#logout" onClick={() => auth.signOut()}>
                Logout
              </Nav.Link>
            </>
          ) : (
            <LinkContainer to="/login">
              <Nav.Link>Login</Nav.Link>
            </LinkContainer>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MenuBar;
