import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, query, limit, doc, updateDoc, setDoc } from 'firebase/firestore';
import '../App.css';
import { Table, Button } from 'react-bootstrap';
import EditTopic from './editTopic';
import CreateTopic from './createTopic';
import updateAllTopics from './updateAllTopics';

const Topics = () => {
  const [topics, setTopics] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [user, setUser] = useState(null);
  const [editingTopic, setEditingTopic] = useState(null);
  const [creatingTopic, setCreatingTopic] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchTopics = async () => {
      if (!user) return;

      try {
        console.debug("Running Query");
        const topicsQuery = query(collection(db, 'TopicDetails'), limit(10));
        const querySnapshot = await getDocs(topicsQuery);
        if (querySnapshot.empty) {
          console.log("Collection 'TopicDetails' does not exist or is empty.");
        } else {
          console.log("Collection 'TopicDetails' exists.");
          const topicsList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setTopics(topicsList);
        }
      } catch (error) {
        console.error("Error fetching topics: ", error);
      }
    };

    const fetchSubjects = async () => {
      try {
        const subjectsQuery = query(collection(db, 'SubjectDetails'));
        const querySnapshot = await getDocs(subjectsQuery);
        const subjectsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSubjects(subjectsList);
      } catch (error) {
        console.error("Error fetching subjects: ", error);
      }
    };

    fetchTopics();
    fetchSubjects();
  }, [user]);

  const handleEditClick = (topic) => {
    setEditingTopic(topic);
  };

  const handleCreateClick = () => {
    setCreatingTopic(true);
  };

  const handleSave = async (updatedTopic) => {
    if (!user) return;
    try {
      const topicRef = doc(db, 'TopicDetails', updatedTopic.id);
      await updateDoc(topicRef, updatedTopic);
      setTopics((prevTopics) =>
        prevTopics.map((topic) => (topic.id === updatedTopic.id ? updatedTopic : topic))
      );
      setEditingTopic(null);
      await updateAllTopics(user); 
    } catch (error) {
      console.error("Error updating topic: ", error);
    }
  };

  const handleCreateSave = async (newTopic) => {
    if (!user) return;
    try {
      const docRef = doc(db, 'TopicDetails', newTopic.id);  // Using UUID as document ID
      await setDoc(docRef, newTopic);
      setTopics((prevTopics) => [...prevTopics, newTopic]);
      setCreatingTopic(false);
      await updateAllTopics(user);
    } catch (error) {
      console.error("Error creating topic: ", error);
    }
  };

  return (
    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        { user && (
          creatingTopic ? (
              <h2>Create new topic</h2>
            ) : editingTopic ? (
              <h2>Edit topic</h2>
            ) : (
              <>
                <h2 style={{ marginRight: '50px', marginTop: '10px' }}>Topics</h2>
                <Button variant="success" onClick={handleCreateClick}>
                  New
                </Button>
              </>
            )
          )
        }
      </div>
      {user ? (
        creatingTopic ? (
          <CreateTopic subjects={subjects} onSave={handleCreateSave} onCancel={() => setCreatingTopic(false)} />
        ) : editingTopic ? (
          <EditTopic topic={editingTopic} subjects={subjects} onSave={handleSave} onCancel={() => setEditingTopic(null)} />
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Subject Title</th>
                <th>Training Video</th>
                <th>Training Text</th>
                <th>ChatGPT Prompt</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {topics.map((topic) => {
                const subject = subjects.find(sub => sub.id === topic.subjectId);
                return (
                  <tr key={topic.id}>
                    <td>{topic.id}</td>
                    <td>{topic.title}</td>
                    <td>{subject ? subject.title : 'Unknown'}</td>
                    <td>
                      <a href={topic.trainingVideoLocation} target="_blank" rel="noopener noreferrer">
                        {topic.trainingVideoLocation}
                      </a>
                    </td>
                    <td>{topic.trainingTextBody}</td>
                    <td>{topic.chatGPTSystemInput}</td>
                    <td>
                      <Button variant="primary" onClick={() => handleEditClick(topic)}>
                        Edit
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )
      ) : (
        <p>You must be signed in to view the topics.</p>
      )}
    </div>
  );
};

export default Topics;
