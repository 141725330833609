import { getDocs, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

const updateAllSubjects = async (user) => {
  if (!user) return;
  try {
    const subjectsSnapshot = await getDocs(collection(db, "SubjectDetails"));
    const allSubjects = {};
    subjectsSnapshot.forEach((doc) => {
      const data = doc.data();

      allSubjects[doc.id] = {
        title: data.title,
        lastUpdateTime: data.lastUpdateTime,
      };
    });

    const allSubjectsRef = doc(db, "Lists", "AllSubjects");
    await setDoc(allSubjectsRef, allSubjects);
    console.log("AllSubjects document updated successfully.");
  } catch (error) {
    console.error("Error updating AllSubjects document: ", error);
  }
};

export default updateAllSubjects;
