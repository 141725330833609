import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "../App.css";

const EditSubject = ({ subject, onSave, onCancel }) => {
  const [formData, setFormData] = useState({ ...subject });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedSubject = {
      ...formData,
      lastUpdateTime: new Date().toISOString(),
    };
    onSave(updatedSubject);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formTitle" className="form-group">
        <Form.Control
          type="text"
          placeholder="Enter title"
          name="title"
          value={formData.title}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formSummary" className="form-group">
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter summary text"
          name="summary"
          value={formData.summary}
          onChange={handleChange}
        />
      </Form.Group>
      <Button variant="success" type="submit" style={{ marginRight: "10px" }}>
        Save
      </Button>
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </Form>
  );
};

export default EditSubject;
