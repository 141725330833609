import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, query, limit, doc, updateDoc, setDoc } from 'firebase/firestore';
import '../App.css';
import { Table, Button } from 'react-bootstrap';
import EditSupportTicket from './editSupportTicket';
import CreateSupportTicket from './createSupportTicket';
import ReadSupportTicket from './readSupportTicket';  // Import the ReadSupportTicket component

const SupportTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [user, setUser] = useState(null);
  const [editingTicket, setEditingTicket] = useState(null);
  const [creatingTicket, setCreatingTicket] = useState(false);
  const [readingTicket, setReadingTicket] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchTickets = async () => {
      if (!user) return;

      try {
        const ticketsQuery = query(collection(db, 'SupportTickets'), limit(10));
        const querySnapshot = await getDocs(ticketsQuery);
        if (querySnapshot.empty) {
          console.log("Collection 'SupportTickets' does not exist or is empty.");
        } else {
          console.log("Collection 'SupportTickets' exists.");
          const ticketsList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setTickets(ticketsList);
        }
      } catch (error) {
        console.error("Error fetching tickets: ", error);
      }
    };

    fetchTickets();
  }, [user]);

  const handleEditClick = (ticket) => {
    setEditingTicket(ticket);
  };

  const handleCreateClick = () => {
    setCreatingTicket(true);
  };

  const handleViewClick = (ticket) => {
    setReadingTicket(ticket);
  };

  const handleSave = async (updatedTicket) => {
    if (!user) return;

    try {
      const ticketRef = doc(db, 'SupportTickets', updatedTicket.id);
      await updateDoc(ticketRef, updatedTicket);
      setTickets((prevTickets) =>
        prevTickets.map((ticket) => (ticket.id === updatedTicket.id ? updatedTicket : ticket))
      );
      setEditingTicket(null);
    } catch (error) {
      console.error("Error updating ticket: ", error);
    }
  };

  const handleCreateSave = async (newTicket) => {
    if (!user) return;

    try {
      const docRef = doc(db, 'SupportTickets', newTicket.id);  // Using UUID as document ID
      await setDoc(docRef, newTicket);
      setTickets((prevTickets) => [...prevTickets, newTicket]);
      setCreatingTicket(false);
    } catch (error) {
      console.error("Error creating ticket: ", error);
    }
  };

  return (
    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        { user && (
          creatingTicket ? (
              <h2>Create new support ticket</h2>
            ) : editingTicket ? (
              <h2>Edit support ticket</h2>
            ) : readingTicket ? (
                <h2>Support Ticket</h2>
            ) : (
              <>
                <h2 style={{ marginRight: '50px', marginTop: '10px' }}>Support Tickets</h2>
                <Button variant="success" onClick={handleCreateClick}>
                  New
                </Button>
              </>
            )
          )
        }
      </div>
      {user ? (
        creatingTicket ? (
          <CreateSupportTicket onSave={handleCreateSave} onCancel={() => setCreatingTicket(false)} />
        ) : editingTicket ? (
          <EditSupportTicket ticket={editingTicket} onSave={handleSave} onCancel={() => setEditingTicket(null)} />
        ) : readingTicket ? (
          <ReadSupportTicket ticket={readingTicket} onClose={() => setReadingTicket(null)} />
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>User ID</th>
                <th>Date</th>
                <th>Subject</th>
                <th>Message</th>
                <th>Device Type</th>
                <th>Version Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((ticket) => (
                <tr key={ticket.id}>
                  <td>{ticket.id}</td>
                  <td>{ticket.userId}</td>
                  <td>{new Date(ticket.date).toLocaleString()}</td>
                  <td>{ticket.subject}</td>
                  <td>{ticket.message}</td>
                  <td>{ticket.deviceType}</td>
                  <td>{ticket.versionNumber}</td>
                  <td>
                    <Button variant="info" onClick={() => handleViewClick(ticket)}>
                      View
                    </Button>
                    {' '}
                    <Button variant="primary" onClick={() => handleEditClick(ticket)}>
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )
      ) : (
        <p>You must be signed in to view the support tickets.</p>
      )}
    </div>
  );
};

export default SupportTickets;
