// src/Home.js
import React from 'react';
import logo from './logo.svg';
import './App.css';

const Home = () => {
  return (
    <div className="Home">
      <p>
        Welcome
      </p>
    </div>
  );
};

export default Home;
