import React, { useState, useEffect } from "react";
import { database } from "../firebase";
import { ref, onValue, push, remove, set } from "firebase/database";

const RTDatabase = () => {
  const [data, setData] = useState([]);
  const [newItem, setNewItem] = useState("");

  useEffect(() => {
    const itemsRef = ref(database, "items");
    onValue(itemsRef, (snapshot) => {
      const items = snapshot.val();
      const newData = [];
      for (let id in items) {
        newData.push({ id, ...items[id] });
      }
      setData(newData);
    });
  }, []);

  const addItem = () => {
    const itemsRef = ref(database, "items");
    const newItemRef = push(itemsRef);
    set(newItemRef, {
      name: newItem,
    });
    setNewItem("");
  };

  const deleteItem = (id) => {
    const itemRef = ref(database, `items/${id}`);
    remove(itemRef);
  };

  return (
    <div>
      <input
        type="text"
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
        placeholder="Add a new item"
      />
      <button onClick={addItem}>Add Item</button>
      <ul>
        {data.map((item) => (
          <li key={item.id}>
            {item.name}
            <button onClick={() => deleteItem(item.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RTDatabase;
