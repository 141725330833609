import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { auth } from "./firebase"; // Import the Firebase auth object
import MenuBar from "./menuBar";
import Login from "./login";
import Home from "./home";
import RTDatabase from "./rtDatabase/rtDatabase";
import Subjects from "./subjects/subjects";
import Topics from "./topics/topics";
import SupportTickets from "./supportTickets/supportTickets";
import { Spinner } from "react-bootstrap";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Router>
      <MenuBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        {user ? (
          <>
            <Route path="/subjects" element={<Subjects />} />
            <Route path="/topics" element={<Topics />} />
            <Route path="/supportTickets" element={<SupportTickets />} />
            <Route path="/rtdatabase" element={<RTDatabase />} />
          </>
        ) : (
          <Route path="/topics" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
