import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, query, limit, doc, updateDoc, setDoc } from 'firebase/firestore';
import '../App.css';
import { Table, Button } from 'react-bootstrap';
import EditSubject from './editSubject';
import CreateSubject from './createSubject';
import updateAllSubjects from './updateAllSubjects';

const Subjects = () => {
  const [subjects, setSubjects] = useState([]);
  const [user, setUser] = useState(null);
  const [editingSubject, setEditingSubject] = useState(null);
  const [creatingSubject, setCreatingSubject] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchSubjects = async () => {
      if (!user) return;

      try {
        console.debug("Running Query");
        const subjectsQuery = query(collection(db, 'SubjectDetails'), limit(10));
        const querySnapshot = await getDocs(subjectsQuery);
        if (querySnapshot.empty) {
          console.log("Collection 'SubjectDetails' does not exist or is empty.");
        } else {
          console.log("Collection 'SubjectDetails' exists.");
          const subjectsList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setSubjects(subjectsList);
        }
      } catch (error) {
        console.error("Error fetching subjects: ", error);
      }
    };

    fetchSubjects();
  }, [user]);

  const handleEditClick = (subject) => {
    setEditingSubject(subject);
  };

  const handleCreateClick = () => {
    setCreatingSubject(true);
  };

  const handleSave = async (updatedSubject) => {
    try {
      const subjectRef = doc(db, 'SubjectDetails', updatedSubject.id);
      await updateDoc(subjectRef, updatedSubject);
      setSubjects((prevSubjects) =>
        prevSubjects.map((subject) => (subject.id === updatedSubject.id ? updatedSubject : subject))
      );
      setEditingSubject(null);
      await updateAllSubjects(user);
    } catch (error) {
      console.error("Error updating subject: ", error);
    }
  };
  

  const handleCreateSave = async (newSubject) => {
    try {
      const docRef = doc(db, 'SubjectDetails', newSubject.id);  // Using UUID as document ID
      await setDoc(docRef, newSubject);
      setSubjects((prevSubjects) => [...prevSubjects, newSubject]);
      setCreatingSubject(false);
      await updateAllSubjects(user);
    } catch (error) {
      console.error("Error creating subject: ", error);
    }
  };
  

  return (
    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        { user && (
          creatingSubject ? (
              <h2>Create new subject</h2>
            ) : editingSubject ? (
              <h2>Edit subject</h2>
            ) : (
              <>
                <h2 style={{ marginRight: '50px', marginTop: '10px' }}>Subjects</h2>
                <Button variant="success" onClick={handleCreateClick}>
                  New
                </Button>
              </>
            )
          )
        }
      </div>
      {user ? (
        creatingSubject ? (
          <CreateSubject onSave={handleCreateSave} onCancel={() => setCreatingSubject(false)} />
        ) : editingSubject ? (
          <EditSubject subject={editingSubject} onSave={handleSave} onCancel={() => setEditingSubject(null)} />
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Summary</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subjects.map((subject) => (
                <tr key={subject.id}>
                  <td>{subject.id}</td>
                  <td>{subject.title}</td>
                  <td>{subject.summary}</td>
                  <td>
                    <Button variant="primary" onClick={() => handleEditClick(subject)}>
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )
      ) : (
        <p>You must be signed in to view the subjects.</p>
      )}
    </div>
  );
};

export default Subjects;
