import { getDocs, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

const updateAllTopics = async (user) => {
  if (!user) return;
  try {
    // Fetch all subjects
    const subjectsSnapshot = await getDocs(collection(db, "SubjectDetails"));
    const subjects = {};
    subjectsSnapshot.forEach((doc) => {
      const data = doc.data();
      subjects[doc.id] = data.title;
    });

    // Fetch all topics
    const topicsSnapshot = await getDocs(collection(db, "TopicDetails"));
    const allTopics = {};
    topicsSnapshot.forEach((doc) => {
      const data = doc.data();

      allTopics[doc.id] = {
        subjectId: data.subjectId,
        subjectTitle: subjects[data.subjectId],
        title: data.title,
        lastUpdateTime: data.lastUpdateTime,
      };
    });

    // Update AllTopics document
    const allTopicsRef = doc(db, "Lists", "AllTopics");
    await setDoc(allTopicsRef, allTopics);
    console.log("AllTopics document updated successfully.");
  } catch (error) {
    console.error("Error updating AllTopics document: ", error);
  }
};

export default updateAllTopics;
